import { Controller } from "@hotwired/stimulus"
import imageCompression from "browser-image-compression"

export default class extends Controller {
  static targets = ["input", "output", "compressionInfo", "downloadLink", "resetButton", "fileType", "maxWidthOrHeight", "initialQuality"]

  async compress() {
    const input = this.inputTarget
    const output = this.outputTarget
    const compressionInfo = this.compressionInfoTarget
    const downloadLink = this.downloadLinkTarget
    const resetButton = this.resetButtonTarget

    if (input.files.length === 0) {
      alert("Please select an image to compress.")
      return
    }

    const file = input.files[0]
    const options = {
      maxSizeMB: 1,
      maxWidthOrHeight: this.maxWidthOrHeightTarget.value ? parseInt(this.maxWidthOrHeightTarget.value, 10) : undefined,
      useWebWorker: true,
      fileType: this.fileTypeTarget.value,
      initialQuality: this.initialQualityTarget.value ? parseFloat(this.initialQualityTarget.value) : 1,
      onProgress: (progress) => {
        compressionInfo.innerHTML = `Compression Progress: ${progress}%`
      }
    }

    try {
      const originalSize = file.size
      const compressedFile = await imageCompression(file, options)
      const compressedSize = compressedFile.size
      const compressedFileUrl = URL.createObjectURL(compressedFile)

      output.src = compressedFileUrl
      output.style.display = "block"

      const compressionAmount = ((originalSize - compressedSize) / originalSize * 100).toFixed(2)
      compressionInfo.innerHTML += `<br>Original Size: ${(originalSize / 1024).toFixed(2)} KB<br>Compressed Size: ${(compressedSize / 1024).toFixed(2)} KB<br>Reduction: ${compressionAmount}%`
      compressionInfo.style.display = "block"

      // Apply color coding based on reduction amount
      let color
      if (compressionAmount >= 50) {
        color = 'green'
      } else if (compressionAmount >= 20) {
        color = 'orange'
      } else {
        color = 'red'
      }
      compressionInfo.style.color = color

      // Update the download link
      downloadLink.href = compressedFileUrl
      downloadLink.download = 'compressed_' + file.name
      downloadLink.textContent = 'Download Compressed Image'
      downloadLink.className = 'Polaris-Button Polaris-Button--primary mt-4'
      downloadLink.style.display = 'inline-block'

      // Show reset button
      resetButton.style.display = 'inline-block'

    } catch (error) {
      console.error("Error compressing the image:", error)
    }
  }

  reset() {
    this.inputTarget.value = ''
    this.outputTarget.style.display = 'none'
    this.compressionInfoTarget.style.display = 'none'
    this.downloadLinkTarget.style.display = 'none'
    this.compressionInfoTarget.innerHTML = ''
    this.resetButtonTarget.style.display = 'none'
  }
}
